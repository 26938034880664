import classNames from "classnames"
import React from "react"

import { DateTime } from "@bounce/date"
import { COUNTRIES, useTranslation } from "@bounce/i18n"
import { Review } from "@bounce/web-components"

import type { Review as TReview } from "../../../../utils/reviews"
import SafeLink from "../../SafeLink/SafeLink"

export type ReviewCardProps = {
  review: TReview
  country?: string
  className?: string
  avatarClassName?: string
  subtitle?: string
}

export const ReviewCard = ({ review, country, className, avatarClassName = "", subtitle }: ReviewCardProps) => {
  const { t, i18n } = useTranslation()
  const name = review.user.fullName || t("cmp.reviewCardNext.anonymous", "Anonymous")

  const reviewCountry = country || review.country
  const emoji = reviewCountry
    ? COUNTRIES.find(country => country.name === reviewCountry || country.code === reviewCountry)?.emoji
    : ""

  const date = DateTime.fromISO(review.completedAt)

  return (
    <Review
      className={classNames("rounded-lg border-2 border-neutral-300 px-5 py-4 xl:px-8 xl:py-6", className)}
      feedback={review.translatedFeedback ?? review.feedback}
      name={name}
      rating={review.rating}
      externalSource={review.externalSource}
      externalSourceAttribution={
        review.externalSource ? (
          <>
            {t("cmp.reviewCardNext.externalSource", "From:")}{" "}
            <SafeLink href={review.externalSource} className="review__external-source underline">
              Google
            </SafeLink>
          </>
        ) : undefined
      }
      avatarSrc={review.user.image ?? undefined}
      avatarClassName={avatarClassName}
      avatarAlt={t(
        "cmp.reviewCardNext.photoAltText",
        "Photo of {name}, a customer who reviewed Bounce in {location}.",
        {
          name,
          location: review.city.translatedName,
        },
      )}
      metaData={
        <div>
          {t("cmp.reviewCardNext.reviewLocation", "Bounced in {location}", { location: review.city.translatedName })}{" "}
          {emoji}
          <br />
          <time dateTime={date.toISODate()} suppressHydrationWarning>
            {date.toRelative({ locale: i18n.language, unit: ["years", "months", "weeks", "days", "hours", "minutes"] })}
          </time>
        </div>
      }
      subtitle={subtitle}
    />
  )
}
