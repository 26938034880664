import { Title } from "./Title"
import type { Size, TitleProps } from "./types"

const createVariant = (size: Size) => {
  const TitleVariant: React.FC<Omit<TitleProps, "size">> = props => <Title {...props} size={size} />
  TitleVariant.displayName = `Title${size}`
  return TitleVariant
}

export const Title1 = createVariant(1)
export const Title2 = createVariant(2)
export const Title3 = createVariant(3)
export const Title4 = createVariant(4)
export const Title5 = createVariant(5)
export const Title6 = createVariant(6)
