import { getLabelClasses, getMarketingLabelClasses } from "./style"
import type { LabelProps } from "./types"
import { useIsAppTheme } from "../../../contexts/Theme/Theme"

export const Label = ({ testId, children, size = 1, className, as: As = "span", numberOfLines, role }: LabelProps) => {
  const isAppTheme = useIsAppTheme()
  const getClasses = isAppTheme ? getLabelClasses : getMarketingLabelClasses

  return (
    <As data-testid={testId ?? `label-${size}`} role={role} className={getClasses({ size, numberOfLines, className })}>
      {children}
    </As>
  )
}
