import { Label } from "./Label"
import type { LabelSize, LabelProps } from "./types"

const createVariant = (size: LabelSize) => {
  const LabelVariant: React.FC<Omit<LabelProps, "size">> = props => <Label {...props} size={size} />
  LabelVariant.displayName = `Label${size}`
  return LabelVariant
}

export const Label1 = createVariant(1)
export const Label2 = createVariant(2)
export const Label3 = createVariant(3)
