import { getMarketingTitleClasses, getTitleClasses } from "./style"
import type { HeadingLevel, TitleProps } from "./types"
import { useIsAppTheme } from "../../../contexts/Theme/Theme"

export const headingElements: { [N in HeadingLevel]: `h${N}` } = {
  1: "h1",
  2: "h2",
  3: "h3",
  4: "h4",
  5: "h5",
  6: "h6",
}

export const Title = ({
  size,
  level = size,
  children,
  className,
  testId,
  style: _style,
  numberOfLines,
  ...rest
}: TitleProps) => {
  const isAppTheme = useIsAppTheme()

  const H = level ? headingElements[level] : "div"
  const getClasses = isAppTheme ? getTitleClasses : getMarketingTitleClasses

  return (
    <H
      data-testid={testId ?? `title-${size}-level-${level}`}
      className={getClasses({ size, className, numberOfLines })}
      {...rest}>
      {children}
    </H>
  )
}
