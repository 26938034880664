import clsx from "clsx"

import type { TitleProps } from "./types"
import { getLineClampClass } from "../style"

export const getTitleClasses = ({
  size,
  className,
  numberOfLines,
}: Pick<TitleProps, "size" | "className" | "numberOfLines">): string => {
  return clsx(
    "font-sans",
    // Font weight and line height
    size <= 2 ? "font-bold leading-[1.2]" : "font-semibold leading-[1.3]",
    // Text sizes
    {
      1: "text-[2rem] lg:text-[2.5rem]",
      2: "text-[1.5rem] lg:text-[1.75rem] lg:leading-[1.3]",
      3: "text-[1.25rem] lg:text-[1.5rem]",
      4: "text-[1.125rem] lg:text-[1.25rem]",
      5: "text-[1rem] lg:text-[1.125rem]",
      6: "text-[0.875rem]",
    }[size],
    // Letter spacing: 1% for all titles except size 6
    {
      1: "tracking-[-0.02rem] lg:tracking-[-0.025rem]",
      2: "tracking-[-0.015rem] lg:tracking-[-0.0175rem]",
      3: "tracking-[-0.0125rem] lg:tracking-[-0.015rem]",
      4: "tracking-[-0.01125rem] lg:tracking-[-0.0125rem]",
      5: "tracking-[-0.01rem] lg:tracking-[-0.01125rem]",
      6: "",
    }[size],
    getLineClampClass(numberOfLines),
    // Extra classes
    className,
  )
}

export const getMarketingTitleClasses = ({
  size,
  className,
  numberOfLines,
}: Pick<TitleProps, "size" | "className" | "numberOfLines">): string => {
  const fontWeight = "font-[--bounce-display-font-weight,700]"

  return clsx(
    "font-display leading-[1.2] tracking-[0.01em] lg:tracking-normal",
    // Font weight
    fontWeight,
    // Text sizes
    {
      1: "text-[2.25rem] lg:text-[3rem]",
      2: "text-[2rem] lg:text-[2.625rem]",
      3: "text-[1.8125rem] lg:text-[2.375rem]",
      4: "text-[1.625rem] lg:text-[2rem]",
      5: "text-[1.375rem] lg:text-[1.625rem]",
      6: "text-[1.125rem] lg:text-[1.375rem]",
    }[size],
    getLineClampClass(numberOfLines),
    // Extra classes
    className,
  )
}
