import classNames from "classnames"
import React from "react"

import { Label2, Title6 } from "@bounce/design-system"
import type { ReviewMetaDataProps } from "@bounce/web-components"
import { Avatar, RatingStars } from "@bounce/web-components"

import { Truncate } from "../Truncate/Truncate"

export type ReviewProps = {
  feedback?: string | React.ReactNode
  className?: string
  externalSource?: string | null
  externalSourceAttribution?: React.ReactNode
  subtitle?: string
} & ReviewMetaDataProps

export const Review = ({
  feedback,
  className,
  rating,
  avatarAlt,
  avatarClassName,
  avatarSrc,
  metaData,
  name,
  externalSource,
  externalSourceAttribution,
  withAvatar = true,
  subtitle,
}: ReviewProps) => (
  <div className={classNames("review", className)}>
    {!!subtitle && <Title6>{subtitle}</Title6>}
    <RatingStars ratingScore={rating} max={5} className="mb-0" starClassName="size-6 text-orange-700" />
    {!!feedback && (
      <Truncate className="review__feedback paragraph1" minLines={4}>
        {feedback}
      </Truncate>
    )}
    <div className={classNames("max-w-[90%]", feedback ? "mt-4" : "mt-auto")}>
      {!!externalSource && <div className="review__external-source">{externalSourceAttribution}</div>}
      <div className={classNames("review__footer", avatarClassName)}>
        {withAvatar && (
          <Avatar
            name={name ?? ""}
            src={avatarSrc}
            alt={avatarAlt}
            loading="lazy"
            className={classNames("!size-16 rotate-[-5.71deg] rounded-none border-4 border-[#F9EDD8]", avatarClassName)}
          />
        )}
        <div className="flex flex-col justify-center">
          <span className="label1">{name}</span>
          {!!metaData && (
            <Label2 className="review__metadata">
              <span suppressHydrationWarning>{metaData}</span>
            </Label2>
          )}
        </div>
      </div>
    </div>
  </div>
)
