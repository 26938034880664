import classNames from "classnames"
import { useEffect, useRef, useState } from "react"

import { getLineClampClass } from "@bounce/design-system/src/components/Typography/style"
import { useTranslation } from "@bounce/i18n"

type TruncateProps = {
  children: React.ReactNode
  className?: string
  minLines?: 1 | 2 | 3 | 4
}

export const Truncate = ({ className, children, minLines = 3 }: TruncateProps) => {
  const { t } = useTranslation("web-components")
  const ref = useRef<HTMLParagraphElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isClamped, setIsClamped] = useState(false)

  useEffect(() => {
    if (ref.current) {
      setIsClamped(ref.current.scrollHeight > ref.current.clientHeight)
    }
  }, [])

  return (
    <>
      <p
        ref={ref}
        className={classNames(
          className,
          getLineClampClass(isOpen ? undefined : minLines),
          "overflow-hidden text-ellipsis",
        )}>
        {children}
      </p>
      {isClamped && (
        <a className="cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? t("cmp.truncate.open", "Show less") : t("cmp.truncate.closed", "Show more")}
        </a>
      )}
    </>
  )
}
