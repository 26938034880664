import clsx from "clsx"

import type { LabelProps, LabelSize } from "./types"
import { getLineClampClass } from "../style"

type LabelClassArgs = Pick<LabelProps, "className" | "numberOfLines"> & {
  size: NonNullable<LabelSize>
}

export const getLabelClasses = ({ size, className, numberOfLines }: LabelClassArgs): string => {
  return clsx(
    "font-sans font-medium",
    size === 1 && "text-base",
    size === 2 && "text-sm",
    size === 3 && "text-[0.625rem]",
    size === 3 ? "tracking-wider" : "tracking-wide",
    "leading-none",
    getLineClampClass(numberOfLines),
    className,
  )
}

export const getMarketingLabelClasses = ({ size, className, numberOfLines }: LabelClassArgs): string => {
  return clsx(
    "font-sans font-semibold tracking-[0.01em]",
    "leading-[1.25] lg:leading-[1.3]",
    size === 1 && "text-[1.125rem] lg:text-[1.375rem]",
    size === 2 && "text-[0.875rem] lg:text-[1.125rem]",
    size === 3 && "text-[0.75rem] lg:text-[0.875rem]",
    getLineClampClass(numberOfLines),
    className,
  )
}
